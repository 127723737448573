body {
	margin: 0;
	background-color: #13181c;
	color: white;
	font-family: 'Roboto Flex', sans-serif;
	overflow: hidden;
}

h1 {
	font-size: 120px;
	text-transform: uppercase;
	letter-spacing: -3px;
	font-variation-settings: "wght" 311;
	color: #eec9aa;
	transition: 0.5s ease;
}

h1:hover {
	font-variation-settings: "wght" 682;
	letter-spacing: 5px;
}

h2 {
	font-size: 80px;
}

p {
	font-size: 30px;
}
