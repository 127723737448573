@keyframes fadein {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadein-scroll {
	from {
		opacity: 0;
		transform: translateY(80px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
